/* import __COLOCATED_TEMPLATE__ from './early-stage-banner.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import type IntlService from 'ember-intl/services/intl';
import { type SafeString } from 'handlebars';
import type RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';

const TRIAL = 0;

interface Args {
  appWide: boolean;
}

interface Signature {
  Element: any;
  Args: Args;
}

export default class EarlyStageBanner extends Component<Signature> {
  @service declare customerService: any;
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  get customer() {
    return this.customerService.customer;
  }

  get currentDate() {
    return moment.utc();
  }

  get currentEarlyStageYear(): number {
    let now = moment.utc();
    return this.customerService.earlyStageGraduation?.programOverviewData.find(
      (data: any) => now.isSameOrAfter(data.startsAt) && now.isBefore(data.endsAt),
    ).year;
  }

  get is1MonthBeforeProgression() {
    let endDate = this.earlyStageStepEndDate(this.currentEarlyStageYear);
    let oneMonthBeforeEnd = moment.utc(endDate).subtract(1, 'months');
    return this.currentDate.isSameOrAfter(oneMonthBeforeEnd) && this.currentDate.isBefore(endDate);
  }

  programDataForYear(year: number) {
    return this.customerService.earlyStageGraduation?.programOverviewData.find(
      (data: any) => data.year === year,
    );
  }

  earlyStageStepEndDate(year: number) {
    return moment.utc(this.programDataForYear(year).endsAt);
  }

  get earlyStageBannerClassNames() {
    let currentYear = this.currentEarlyStageYear;
    let isTrial = currentYear === 0;

    return isTrial
      ? 'bg-paywall-container border-paywall-border'
      : 'bg-notice-container border-notice-border';
  }

  // shouldShowBanner
  // - this should be under the Billing Summary redesign feature flag and only show for early stage customers
  // - only show if admin is having billing permissions
  // - if cancellation is triggered before progression date, don't show this component
  // Only show during free trial and 1 month before progression
  get shouldShowBanner() {
    return (
      this.permissionsService.currentAdminCan('can_access_billing_settings') &&
      this.appService.app.canUseBillingSummaryRedesign &&
      this.customer.currentlyOnEarlyStage &&
      !this.customer.cancellationRequestedOutsideMigration &&
      this.isCorrectEarlyStageDateCriteria
    );
  }

  get isCorrectEarlyStageDateCriteria() {
    return (
      (!this.customer.subscriptionCancelledAt ||
        this.customer.subscriptionCancelledAt.isAfter(
          this.earlyStageStepEndDate(this.currentEarlyStageYear),
        )) &&
      (this.currentDate.isBefore(this.earlyStageStepEndDate(TRIAL)) ||
        this.is1MonthBeforeProgression)
    );
  }

  get earlyStageBannerTextTranslation(): string | SafeString {
    let currentYear = this.currentEarlyStageYear;
    let currentData = this.programDataForYear(currentYear);

    if (!currentData) {
      return '';
    }

    let endDate = moment.utc(currentData.endsAt);
    let formattedEndDate = endDate.format('MMMM D, YYYY');

    let nextData = this.programDataForYear(currentYear + 1);
    let translationKey =
      currentYear === 0
        ? 'pricing-and-packaging.early_stage_banner.trial'
        : `pricing-and-packaging.early_stage_banner.year_${currentYear}`;

    return this.intl.t(translationKey, {
      discount: currentData.discount,
      nextDiscount: nextData?.discount,
      formattedEndDate,
      htmlSafe: true,
    });
  }

  @action navigateToUpdateSubscription() {
    this.router.transitionTo('apps.app.settings.subscription.billing.subscription-details.index');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Banners::EarlyStageBanner': typeof EarlyStageBanner;
    'banners/early-stage-banner': typeof EarlyStageBanner;
  }
}
