/* import __COLOCATED_TEMPLATE__ from './lose-benefits-modal.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { startSurvey } from 'embercom/lib/intercom-widget-helper';
import { SELF_SERVE_CHURN_SURVEY_ID } from 'embercom/lib/billing/surveys';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type Plan from 'embercom/models/plan';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';

interface Signature {
  Args: {
    isOpen: boolean;
    onModalClose: () => void;
  };
}

const BENEFIT_CARDS = {
  TRIAL_FIN: 'trial-fin',
  CONNECT_CHANNELS: 'connect-channels',
  SEND_MESSAGE: 'send-message',
};

export default class LoseBenefitsModal extends Component<Signature> {
  @service appService: any;
  @service intercomEventService: any;
  @service declare customerService: $TSFixMe;
  @service declare router: RouterService;
  @service declare intl: IntlService;

  listOfBenefits = Object.values(BENEFIT_CARDS);

  get app() {
    return this.appService.app;
  }

  get planName() {
    if (this.currentSubscriptionPlan) {
      return this.currentSubscriptionPlan.name;
    }
    let graduatingTrialPlanName = this.customerService.activePlans?.find(
      (plan: Plan) => !plan.product.addon,
    )?.name;
    if (graduatingTrialPlanName) {
      return graduatingTrialPlanName;
    }
    return this.intl.t('billing.settings.lose-benefits-modal.cta.plan-name-fallback');
  }

  get currentSubscriptionPlan() {
    return this.app.allPlansOnPricingModel.find(
      (plan: Plan) => plan.billableCustomerPlan && !plan.product.addon,
    );
  }

  @action
  thumbnailAssetUrl(benefit: string, size: undefined | '2x' = undefined): string {
    let thumbnailFileName = `benefits-modal-${benefit}-thumbnail${size ? `@${size}` : ''}.png`;
    return assetUrl(`/assets/images/settings/${thumbnailFileName}${size ? ` ${size}` : ''}`);
  }

  @action
  benefitCtaRoute(benefit: string): string {
    switch (benefit) {
      case BENEFIT_CARDS.TRIAL_FIN:
        return 'apps.app.automation.fin-ai-agent.content';
      case BENEFIT_CARDS.CONNECT_CHANNELS:
        return 'apps.app.settings.channels.all';
      case BENEFIT_CARDS.SEND_MESSAGE:
      default:
        return 'apps.app.outbound.all';
    }
  }

  @action
  benefitVideoId(benefit: string): string {
    switch (benefit) {
      case BENEFIT_CARDS.TRIAL_FIN:
        return '7xgvdmpwhy';
      case BENEFIT_CARDS.CONNECT_CHANNELS:
        return '7xgvdmpwhy'; // TODO: update video id, not available in figma
      case BENEFIT_CARDS.SEND_MESSAGE:
      default:
        return '27un2555f0';
    }
  }

  @action
  triggerTransition(benefit: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `${benefit}_cta`,
      section: 'cancel_subscription.lose_benefits_modal',
      place: 'billing_settings',
    });
    this.router.transitionTo(this.benefitCtaRoute(benefit));
  }

  @action
  handleCancellation() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'continue_to_cancel_subscription',
      section: 'cancel_subscription.lose_benefits_modal',
      place: 'billing_settings',
    });
    this.args.onModalClose();
    if (this.app.canSeeSelfServeChurnSurvey) {
      startSurvey(SELF_SERVE_CHURN_SURVEY_ID);
    } else {
      this.router.transitionTo('apps.app.settings.subscription.billing.cancel', this.app.id); // Fallback for EU/AU workspaces
    }
  }

  @action
  handleModalClose(object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      section: 'cancel_subscription.lose_benefits_modal',
      place: 'billing_settings',
      context: 'abort_cancel_subscription',
    });
    this.args.onModalClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Settings::LoseBenefitsModal': typeof LoseBenefitsModal;
    'billing/settings/lose-benefits-modal': typeof LoseBenefitsModal;
  }
}
